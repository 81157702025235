import React from "react"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import Seo from "../components/seo"
import FlexRow from "components/containers/flexRow"
import FlexColumn from "components/containers/flexColumn"
import GeneralForm from "components/GeneralForm"
import Box from "@mui/material/Box"

/*----------------------------------------------------------------
*******************************

Contact Us/ Me page for Slick Blue Dragon

This page primarily is accessed by a button if the user needs to submit a general enquiry.

*******************************
----------------------------------------------------------------*/

const contact = () => {
  return (
    <>
      <Container
        maxWidth="false"
        sx={{
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <FlexRow
          sx={{
            justifyContent: "center",
            paddingTop: "2em",
            paddingBottom: "3em",
          }}
        >
          <FlexColumn sx={{ alignItems: "center" }}>
            <Typography
              variant="h1"
              sx={{
                "&.MuiTypography-h1": {
                  fontSize: "2.25rem",
                  fontWeight: "500",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                },
              }}
            >
              Contact Us Here
            </Typography>
            <Typography variant="body1">
              If you have a general enquiry about what we do, our rates or have
              another question. Please get in touch with me below.
            </Typography>
          </FlexColumn>
        </FlexRow>
      </Container>
      <Box
        sx={{
          paddingBottom: "2.75em",
          paddingTop: "2.75em",
          backgroundColor: "secondary.main",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "700px",
            width: { xs: "95%", md: "80%" },
            padding: "1em 5%",
            backgroundColor: "primary.offWhite",
            border: "0.25em solid black",
            borderRadius: "0.5em",
            boxShadow: "0.5em 0.5em 0.75em",
          }}
        >
          <GeneralForm />
        </Container>
      </Box>
    </>
  )
}

export default contact

export function Head() {
  return (
    <Seo
      title="Contact Slick Blue Dragon for website enquiries - Peterborough, UK"
      description="Get in touch with Slick Blue Dragon for freelance web development enquiries and discover what's possible for your business on the web."
    />
  )
}
