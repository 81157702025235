import React from "react"
import { TextField, Button, Typography } from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import { useForm as useFormSpree } from "@formspree/react"
import { FlexRow, FlexColumn } from "components/containers"
import * as yup from "yup"
import { DevTool } from "@hookform/devtools"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextFieldStyles } from "components/TextFieldStyles"

const GeneralForm = () => {
  const textFieldStyles = TextFieldStyles()

  const schema = yup.object().shape({
    yourName: yup
      .string()
      .required("Name is required")
      .min(6, "Your name should be at least 6 characters.")
      .max(60),

    yourEmail: yup.string().email().required(),
    message: yup
      .string()
      .max(500, "That's more than 500 characters.")
      .required("Please don't forget your message."),
  })

  const {
    handleSubmit,
    control,
    reset,
    setFocus,
    formState: { isDirty, isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: { yourName: "", yourEmail: "", message: "" },
    resolver: yupResolver(schema),
  })

  const [serverState, sendToFormSpree] = useFormSpree("mknyygzr")

  React.useCallback(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return errors[field] ? field : a
    }, null)
    if (firstError) {
      setFocus(firstError)
      console.log("Errors finished ", firstError)
    }
  }, [errors, setFocus])

  React.useCallback(
    data => {
      if (isSubmitSuccessful) {
        // console.log(data)
        reset()
      }
    },
    [isSubmitSuccessful, isDirty]
  )

  if (serverState.succeeded) {
    return (
      <div>
        <h1>
          "Thank you for your enquiry. We'll get back to you as soon as we can."
        </h1>
      </div>
    )
  }
  return (
    <form id="contact-form-general" onSubmit={handleSubmit(sendToFormSpree)}>
      <Typography
        variant="h1"
        sx={{
          "&.MuiTypography-h1": {
            fontSize: "2rem",
            fontWeight: "500",
            paddingTop: "0.5em",
            textAlign: "center",
          },
          width: "100%",
        }}
      >
        Get in Touch
      </Typography>
      <FlexColumn
        sx={{
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "900px",
          marginRight: 0,
        }}
      >
        <FlexRow
          sx={{
            justifyContent: "center",
            width: "100%",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Controller
            name="yourName"
            control={control}
            render={({ field, formState: { errors } }) => (
              <TextField
                id="your-name"
                {...field}
                label="Please enter your full name *"
                margin="normal"
                placeholder="Joe Website"
                error={errors.yourName}
                helperText={errors.yourName?.message}
                // onChange={onChange}
                variant="filled"
                sx={{ ...textFieldStyles }}
              ></TextField>
            )}
          />
        </FlexRow>
        <FlexRow
          sx={{
            justifyContent: "center",
            width: "100%",
            paddingBottom: 0,
          }}
        >
          <Controller
            id="your-email"
            name="yourEmail"
            control={control}
            render={({
              field,
              fieldState: { isDirty, invalid, isTouched },
              formState: { errors },
            }) => (
              <TextField
                sx={{ ...textFieldStyles }}
                variant="filled"
                label="Please enter your email *"
                placeholder="Joe.Website@gmail.com"
                error={errors.yourEmail}
                helperText={errors.yourEmail?.message}
                {...field}
              ></TextField>
            )}
          />
        </FlexRow>
        <FlexRow
          sx={{ justifyContent: "center", width: "100%", paddingBottom: 0 }}
        >
          <Controller
            id="message"
            name="message"
            control={control}
            render={({
              field,
              fieldState: { isDirty, invalid, isTouched },
              formState: { errors },
            }) => (
              <TextField
                multiline={true}
                rows={3}
                sx={{ ...textFieldStyles }}
                label="Message *"
                error={errors.message?.message}
                helperText={errors.message?.message || "Maximum 500 characters"}
                {...field}
              ></TextField>
            )}
          />
        </FlexRow>
        <FlexRow
          sx={{ justifyContent: "center", width: "100%", mt: "0.75em", gap: 3 }}
        >
          <Button
            type="submit"
            sx={{ border: "2px solid", width: "80%" }}
            variant="outlined"
          >
            Submit
          </Button>
        </FlexRow>
      </FlexColumn>
      {serverState.errors.map(err => (
        <p>{err.message}</p>
      ))}
      <DevTool control={control}></DevTool>
    </form>
  )
}

export default GeneralForm
